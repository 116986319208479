import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { Box, Card, CardActions, CardContent, CardHeader, Container, Grid, LinearProgress, Link, styled, Typography } from "@mui/material/";
import Page from "../../components/page";
import { Api } from "../../shared/api";
import { AppContext } from "../../shared/appContext";
import { WebHook, WebHookCreateRequest } from "@dragonsight/models";
import Button from "@mui/material/Button";
import { AddAlert } from "../../shared/alert";
import { token } from "../../shared/theme";
import { Formik, FormikHelpers } from "formik";
import TextInput from "../../components/form/textInput";
import { useConfirmModal } from "../../components/confirmModal";

import DeleteIcon from '@mui/icons-material/DeleteForever';
import IconButton from "../../components/iconButton";

const WebhookHeader = styled('div')({
    display: "flex",
    alignItems: "center"
})

const WebhookTitle = styled('h3')({
    margin: 0,
    flexGrow: 1
})

const WebhookUrl = styled('div')({
    fontSize: "12px"
})

const WebhookItem = styled('div')({
    borderColor: "#fff",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    paddingBottom: token.spacing.s200,
    marginBottom: token.spacing.s200,
})

const WebhookList = styled('div')({
    display: "flex",
    flexDirection: "column",
    gap: "8px"
})

const WebhookRow = (props: {webhook: WebHook, onDelete: (id: string) => void}) => {
    const confirmModal = useConfirmModal();
    const [ submitting, setSubmitting ] = useState(false);

    const deleteWebhook = async () => {
        try {
            setSubmitting(true);
            const confirm = await confirmModal.confirm({
                message: "This will remove all conections from games using this web hook.",
                title: "Are you sure?"
            })

            if(confirm){
                await Api.Webhook.Delete(props.webhook.id);
    
                props.onDelete(props.webhook.id);
                AddAlert({
                    message: `Unable to delete [${props.webhook.name}]`,
                    severity: "error",
                });
            }
        } catch(e: any){
            AddAlert({
                message: `Unable to delete [${props.webhook.name}]`,
                severity: "error",
            });
        } finally {
            setSubmitting(false);
        }
    }

    return <WebhookItem>
        <WebhookHeader>
            <WebhookTitle>{props.webhook.name}</WebhookTitle>
            <IconButton disabled={submitting} onClick={deleteWebhook} ><DeleteIcon sx={{ fontSize: 18, verticalAlign: "top" }}   /></IconButton>
        </WebhookHeader>
        <WebhookUrl>URL: {props.webhook.url}</WebhookUrl>
    </WebhookItem>
}

interface WebhookFormProps {
    onCreate: (webhook: WebHook) => void;
}

const WebhookForm = (props: WebhookFormProps) => {
    const [ submitting, setSubmitting ] = useState(false);

    const handleFormSubmit = async (values: WebHookCreateRequest, helpers: FormikHelpers<WebHookCreateRequest>) => {
        setSubmitting(true);
        try {
            const response = await Api.Webhook.Add(values);
            props.onCreate(response);
            helpers.resetForm();
        } catch (e) {
            AddAlert({
                message: "Unable to add web hook.",
                severity: "error"
            })
        } finally {
            setSubmitting(false);
        }
    }
    return <Box marginTop={token.spacing.s400}>
        <Typography fontSize={24}>Add a new webhook</Typography>
        {/* <Typography fontSize={token.font.size.body}>
            A feedback channel a specific stream of feedback items, you may use this for a demo or different versions of your game.
            Once you create a channel you can post from your game using the game-id and channel-id to record feedback directly in your game.
        </Typography> */}
        <Formik<WebHookCreateRequest> initialValues={{name: "", type:"DISCORD", url: "" }} onSubmit={(values, helpers) => handleFormSubmit(values, helpers)}>
        {({
            handleSubmit
        }) => (<>
            <div>Add a new webhook so your games have somewhere to notify your team when you get feedback or some one starts streaming your game.</div>
            <div>We currently support discord webhooks. To get a webhook from discord go into your discrod server settings integration-webhooks and create a webhook in the channel you want. Copy the url it gives you and paste it in the url box here. Name your webhook appropriately and press add.</div>
            <TextInput
                label="Name"
                name="name"
                type="text"
                disabled={submitting}
            />
            <TextInput
                label="Url"
                name="url"
                type="text"
                disabled={submitting}
            />
            <Button disabled={submitting} onClick={() => handleSubmit()}>Add Webhook</Button>
        </>)}
        </Formik>
    </Box>
}

export const IntegrationsPage = () => {
    const navigate = useNavigate();
    const userContext = useContext(AppContext);

    const [ loading, setLoading ] = useState(false);
    const [ webhooks, setWebhooks ] = useState<WebHook[]>([]);

    const handleListWebhooks = async () => {
        setLoading(true);
        try {
            const response = await Api.Webhook.List("");
            setWebhooks(response.items);
        } catch (e) {
            AddAlert({
                message: "Could not load webhooks",
                severity: "error"
            })
        } finally {
            setLoading(false);
        }
    }

    const onWebhookAdded = (webhook: WebHook) => {
        setWebhooks((current) => {
            return [
                ...current,
                webhook
            ]
        })
        AddAlert({
            message: `Web hook [${webhook.name}] has been added`,
            severity: "success"
        })
    }

    const removeWebhook = (id: string) => {
        setWebhooks(previous => {
            return previous.filter(wh => wh.id !== id);
        })
    }

    useEffect(() => {
        handleListWebhooks();
    }, []);

    const content = () => {
        return <WebhookList>
            {webhooks.map(wh => <WebhookRow key={wh.id} webhook={wh} onDelete={removeWebhook} />)}
        </WebhookList>
    }

    return <Page title="Integrations" breadcrumbs={[
        { label: "Home", to: "/"},
        { label: "Integrations" }
    ]}>
        {loading ? "Loading.." : <>
                {content()}
                <WebhookForm onCreate={onWebhookAdded} />
            </>
        }
    </Page>
}