import { styled } from "@mui/material";

export interface StackProps {
    direction?: "row" | "column";
    space?: string;
}

const Stack = styled("div")((props: StackProps) => ({
    display: "flex",
    flexDirection: props.direction,
    gap: props.space
}));

export default Stack;