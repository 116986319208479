import {
  Container,
  CssBaseline,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Account } from "../../models";
import { ConfirmModalProvider } from "./components/confirmModal";
import Header from "./components/header";
import AccountPage from "./pages/account";
import CreateAccountPage from "./pages/account/createAccount";
import AuthPage from "./pages/auth";
import CrmPage from "./pages/crm";
import { HomeDashboardPage } from "./pages/game";
import GamePage from "./pages/game/game";
import { IntegrationsPage } from "./pages/integrations/integrations";
import MissingSubscriptionPage from "./pages/subscription/missingSubsription";
import AlertBar, { AddAlert } from "./shared/alert";
import { Api } from "./shared/api";
import { AppContext, setCurrentAccount } from "./shared/appContext";
import * as SharedAuth from "./shared/auth";
import { singOut } from "./shared/auth";
import { StripeProcessor } from "./shared/processors";
import { theme } from "./shared/theme";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {`© 2022-${new Date().getFullYear()} `}
      <Link color="inherit" href="https://dragonsight.io">
        DragonSight
      </Link>
      {`. All rights reserved.`}
    </Typography>
  );
}

export type AppState = "AUTH" | "SUBSCRIBE" | "ACCOUNT" | "APP";

function App() {
  const [loading, setLoading] = useState(false);

  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(
    undefined,
  );
  const [account, setAccount] = useState<Account | undefined>(undefined);

  const [appState, setAppState] = useState<AppState>("AUTH");

  useEffect(() => {
    const getUser = async () => {
      setLoading(true);
      try {
        const authedUser = await Auth.currentAuthenticatedUser();
        setCognitoUser(authedUser);
      } catch (e) {}
    };

    getUser();
  }, []);

  useEffect(() => {
    const call = async () => {
      setLoading(true);
      try {
        const response = await Api.GetAccount();
        setAccount(response);
        setCurrentAccount(response!);
        const responseAccount: Account = response!;
        if (
          responseAccount.stripeSubscriptionId === undefined ||
          responseAccount.stripeSubscriptionId === null
        ) {
          setAppState("SUBSCRIBE");
        } else {
          setAppState("APP");
        }
      } catch (e: any) {
        if (e.response?.data?.code === "NO_ACCOUNT") {
          setAppState("ACCOUNT");
        } else {
          AddAlert({
            message: e.message,
            severity: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    };
    call();
  }, [cognitoUser]);

  const handleSignout = async () => {
    await singOut();
    setAppState("AUTH");
    setCognitoUser(undefined);
    setAccount(undefined);
    SharedAuth.singOut();
  };

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <ConfirmModalProvider>
          <CssBaseline />
          <LinearProgress />
          <Copyright sx={{ mt: 5 }} />
        </ConfirmModalProvider>
      </ThemeProvider>
    );
  }

  const Content = () => {
    const location = useLocation();
    if (location.pathname === "/stripe") {
      return <StripeProcessor setAppState={setAppState} />;
    }
    if (appState === "AUTH") {
      return (
        <Container maxWidth="xs">
          <AuthPage
            onUserSignIn={(user) => setCognitoUser(user)}
            page="SignIn"
          />
        </Container>
      );
    } else if (appState === "ACCOUNT") {
      return (
        <Container maxWidth="xs">
          <CreateAccountPage />
        </Container>
      );
    } else if (appState === "SUBSCRIBE") {
      return (
        <Container maxWidth="xs" component="main">
          <MissingSubscriptionPage />
        </Container>
      );
    } else if (cognitoUser !== undefined && account !== undefined) {
      return (
        <AppContext.Provider
          value={{
            account: account,
            user: { username: cognitoUser.getUsername() },
            signout: handleSignout,
            changeAccount: () => {},
          }}
        >
          <Header
            sections={[
              { title: "Games", url: "/" },
              { title: "Integrations", url: "/integrations" },
              { title: "Crm", url: "/crm" },
              { title: "Account", url: "/account" },
            ]}
          />
          <Container component="main" maxWidth="lg" sx={{ pt: 8, pb: 6 }}>
            <Routes>
              <Route path="account" element={<AccountPage />} />
              <Route path="integrations" element={<IntegrationsPage />} />
              <Route path="game/:gameId" element={<GamePage />} />
              <Route path="crm" element={<CrmPage />}></Route>
              <Route path="billing/cancel"></Route>
              <Route index element={<HomeDashboardPage />} />
            </Routes>
          </Container>
        </AppContext.Provider>
      );
    } else {
      return <></>;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ConfirmModalProvider>
        <CssBaseline />
        <AlertBar />
        <BrowserRouter>
          <Content />
        </BrowserRouter>
        <Copyright sx={{ mt: 5 }} />
      </ConfirmModalProvider>
    </ThemeProvider>
  );
}

export default App;
